import _Decimal from "decimal.js-light";
import toFormat from "toformat";

// format libraries
const Decimal = toFormat(_Decimal);

// rounding for tables
export const calcRoundingValues = (values: Array<string | number>): number => {
  if (values !== undefined) {
    let N = 0;
    const k = [];

    for (const el of values) {
      const remainder = parseFloat(String(el)) % 1;

      if (+el !== 0 && remainder !== 0) {
        N = Math.round(Math.log10(Math.abs(+el)));

        if (N >= 4) {
          k.push(0);
        } else if (N < 4 && N >= 2) {
          k.push(2);
        } else if (N < 2 && N >= 0) {
          k.push(4);
        } else if (N < 0) {
          if (-N + 3 <= 11) {
            k.push(-N + 3);
          } else k.push(0);
        }
      } else k.push(0);
    }

    return Math.max(...k);
  }
  return 0;
};

// rounding for single values
export const calcRoundingValue = (value: number): number => {
  if (value !== undefined && !isNaN(value)) {
    let N = 0;
    if (value !== 0) {
      N = Math.round(Math.log10(Math.abs(value)));
      if (N >= 0 && N <= 3) {
        return 4;
      }
      if (N < 0) {
        return -N + 4;
      }
      if (N > 3) {
        return 2;
      }
    } else return 0;
  }
  return 0;
};

export const roundNumber = (num: number) => {
  const fractionDigits = calcRoundingValue(num);
  return +num.toFixed(fractionDigits);
};

// converting a number to exponential format

export const convToExp = (value: string | number): string => {
  if (Math.abs(+value) < 0.001) {
    return (+value).toExponential(4);
  }

  return String(value);
};

// rounding method with negative rounding logic

export const toRounding = (v: number, prec: number) => {
  if (prec > 0) return v.toFixed(prec);

  const pow = 10 ** prec;
  const small = v * pow;
  return String(Math.round(Math.round(small) / pow));
};

/**
 * calculate the required accuracy and further round the number
 * @param v - number to be rounded
 * @returns rounded number
 */
export const roundSingleValue = (v: number) => toRounding(v, calcRoundingValue(v));

/**
 * ****************************************************************
 * Converts e-Notation Numbers to Plain Numbers
 * @param   expNum valid Number in exponent format.
 *          pass number as a string for very large 'e' numbers or with large fractions
 *          (none 'e' number returned as is).
 * @return {string} a decimal number string.
Note: No check is made for NaN or undefined input numbers.

***************************************************************
 */
export function expToNumber(expNum: number | string): string {
  let num = expNum.toString();
  let sign = "";
  // eslint-disable-next-line no-unused-expressions
  (num += "").charAt(0) === "-" && ((num = num.substring(1)), (sign = "-"));
  const arr = num.split(/[e]/gi);
  if (arr.length < 2) return sign + num;
  const dot = ".";
  let n = arr[0];
  const exp = +arr[1];
  let w: any = (n = n.replace(/^0+/, "")).replace(dot, "");
  const pos = n.split(dot)[1] ? n.indexOf(dot) + exp : w.length + exp;
  let L: any = pos - w.length;
  const s = `${BigInt(w)}`;
  w =
    // eslint-disable-next-line no-nested-ternary
    exp >= 0
      ? L >= 0
        ? s + "0".repeat(L)
        : r()
      : pos <= 0
      ? `0${dot}${"0".repeat(Math.abs(pos))}${s}`
      : r();
  L = w.split(dot);
  if ((L[0] === 0 && L[1] === 0) || (+w === 0 && +s === 0)) {
    w = 0;
  }
  return sign + w;
  function r() {
    return w.replace(new RegExp(`^(.{${pos}})(.)`), `$1${dot}$2`);
  }
}

export const roundToNDecimals = (num: number, decimals: number = 2) => {
  const factor = 10 ** decimals;
  return Math.round(num * factor) / factor;
};

export const roundToTwoDecimals = (num: number) => roundToNDecimals(num, 2);

export const roundToExp = (value: number, fractionDigits?: number) =>
  value.toExponential(fractionDigits);

export const roundToSignificant = (number: number, significantDigits = 2) => {
  Decimal.set({ precision: significantDigits + 1, rounding: Decimal.ROUND_HALF_UP });
  const updated = new Decimal(number).toSignificantDigits(significantDigits);
  return updated.toFormat(updated.decimalPlaces(), { groupSeparator: "" });
};

export const getExponentValue = (num: number) => {
  if (num === 0) return 0; // Logarithm of zero is not defined
  return Math.floor(Math.log10(Math.abs(num)));
};

export const MAX_PRECISION_DECIMALS = 18;

export const MIN_PRECISION_DECIMALS = -6;

export const getMaxPrecision = (precision: number) => Math.min(precision, MAX_PRECISION_DECIMALS);
