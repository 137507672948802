import { useColorStatus } from "src/helpers/getStatus/getBotStatusColor";
import { InfoIndication } from "src/state/NavigationMenu";
import { isDefined } from "src/helpers/utils";
import Icons from "src/icons/Icons";
import * as styles from "./style";

interface IndicatorProps {
  status?: InfoIndication["status"];
}

export const Indicator = ({ status }: IndicatorProps) => {
  const getColorStatus = useColorStatus();

  if (!isDefined(status)) return null;

  return (
    <styles.IndicatePlate color={getColorStatus(status)}>
      <styles.InfoIconWrapper>
        <Icons.Info />
      </styles.InfoIconWrapper>
    </styles.IndicatePlate>
  );
};
