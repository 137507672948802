import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { DEXRoutes } from "src/app/constants";
import { getBotLink } from "src/components/NavigationMenu/MenuContent/shared/MenuList/utils";
import Icons from "src/icons/Icons";
import { ThemeContext } from "styled-components";
import { MobMessage } from "../../CEXBots/CEXBotMob/style";
import { BotMark } from "../../shared/BotMark";
import { Favorite, MobBotContainer, MobFrame, MobLine } from "../../shared/shared";
import { copyBotCredential } from "../../shared/utils";
import { DEXBotProps } from "../DEXBot";
import * as styles from "./style";

export const DEXBotMob = observer(
  ({
    swap_bot_uuid,
    favorite,
    toggleIsWatched,
    visible,
    client,
    pair,
    exchange,
    link,
    lastTrade,
    nextTrade,
    message,
    colorStatus,
    credential,
    ...props
  }: DEXBotProps) => {
    const {
      market,
      swap_bot_id,
      base,
      note,
      party,
      party_id,
      quote,
      created_at,
      updated_at,
      ...containerProps
    } = props;

    const { botColorStatus: colors, botBgColorStatus: bgColors } = useContext(ThemeContext);

    const botLinkParams = {
      party,
      uuid: swap_bot_uuid,
    };

    return (
      <MobBotContainer {...containerProps}>
        {favorite ? (
          <Favorite onClick={() => toggleIsWatched(swap_bot_uuid)}>
            {visible ? Icons.startStatusTrue() : Icons.startStatusFalse()}
          </Favorite>
        ) : null}

        <MobFrame color={colors[colorStatus]} style={{ backgroundColor: bgColors[colorStatus] }}>
          <styles.Content>
            <styles.PairMob
              to={getBotLink({ botType: "DEX", botTab: DEXRoutes.Stats, ...botLinkParams })}
              color={colors[colorStatus]}
            >
              {pair?.split("_").join(" _ ")}
            </styles.PairMob>

            <styles.MobExch href={link || "#"} target="_blank">
              {" "}
              {exchange}
            </styles.MobExch>

            <styles.TimeMob> {lastTrade}</styles.TimeMob>

            <styles.TimeMob> {nextTrade}</styles.TimeMob>

            <BotMark
              onClick={() => copyBotCredential(credential)}
              color={colors[colorStatus]}
              size="l"
            />
          </styles.Content>

          <styles.Row>
            {message ? (
              <MobMessage style={{ width: "100%", maxWidth: "100%", gridArea: "auto" }}>
                {message}
              </MobMessage>
            ) : (
              <MobLine />
            )}
          </styles.Row>
        </MobFrame>
      </MobBotContainer>
    );
  }
);
