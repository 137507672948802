import { unixToDateFormat } from "src/helpers/dateUtils";
import { getFormattedTimeNotWork } from "src/components/AllBots/Bots/shared/utils";

const statusMapper: Record<string, number> = {
  stopped: 0,
  sleeping: 0,
  error: 2,
  failed: 2,
  active: 3,
};

export const mapStatusToNumber = (status?: string) => {
  if (!status) return null;

  const mappedStatus = statusMapper[status];

  return mappedStatus !== undefined ? mappedStatus : 4;
};

export const getLastUpdateMsg = (time: number) =>
  `Last update: ${unixToDateFormat(time, "FullDate")}`;

export const geCexSettingsMsg = (timeDontWork?: number, message?: string) => {
  const formattedTime = timeDontWork ? getFormattedTimeNotWork(timeDontWork) : "—";

  return `Downtime: ${formattedTime}\n\nMessage: ${message || "—"}`;
};
