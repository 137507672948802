import { MenuItem } from "../shared/MenuList/MenuItem";
import { MenuList } from "../shared/MenuList/style";
import { PARTY_MENU_ITEMS, useGetPartyCurrentTab, useGetPathParams } from ".";
import { useLoadIndication } from "../shared/hooks";

export const PartyMenuList = () => {
  const { party } = useGetPathParams();
  const currentTab = useGetPartyCurrentTab();

  useLoadIndication({ party, currentTab, menuList: PARTY_MENU_ITEMS });

  return (
    <MenuList>
      {PARTY_MENU_ITEMS.map(({ value, ...other }) => (
        <MenuItem<"party">
          key={value}
          useGetCurrentTab={useGetPartyCurrentTab}
          useGetPathParams={useGetPathParams}
          item={{ value, ...other }}
        />
      ))}
    </MenuList>
  );
};
