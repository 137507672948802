export const CEX_SWAP_ROUTES = [
  "cake1",
  "cake2",
  "uni",
  "uni3/1",
  "uni3/0.3",
  "uni3/0.05",
  "uni3/0.01",
  "quick",
  "sushimatic",
  "joe",
  "viper",
  "ape",
  "yode",
  "despace",
  "wingriders",
  "uni3polygon/1",
  "uni3polygon/0.3",
  "uni3polygon/0.05",
  "uni3polygon/0.01",
  "spooky",
  "zyber",
  "sushiarbi",
  "aptcake",
  "quick3",
  "uni3arbi/1",
  "uni3arbi/0.3",
  "uni3arbi/0.05",
  "uni3arbi/0.01",
  "minswap",
  "cakezk",
  "cake3bsc/1",
  "cake3bsc/0.25",
  "cake3bsc/0.05",
  "cake3bsc/0.01",
  "cake2_uni3/1",
  "syncswap",
  "fusionx3/1",
  "fusionx3/0.3",
  "fusionx3/0.05",
  "fusionx3/0.01",
  "raydium",
  "curve",
  "beamswap",
  "netswap",
  "pangolin",
  "lynex",
  "sushi3core/1",
  "sushi3core/0.3",
  "sushi3core/0.05",
  "sushi3core/0.01",
  "dedust",
  "stonfi",
  "sushibase",
  "saucer",
  "saitaswapbsc",
  "saitaswapeth",
  "camelot",
  "uni3base/1",
  "uni3base/0.3",
  "uni3base/0.05",
  "uni3base/0.01",
];
