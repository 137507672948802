import { ApiModules, apiUrl } from "src/api";
import { Schema } from "src/helpers/forms/types/Schema";
import { buildQueryUrl } from "src/helpers/url";
import { Split } from "src/helpers/utils";
import { ApiResponse } from "src/modules/network";
import { ChartPointResponse } from "src/modules/shared";
import ResponseHandler from "src/state/network/ResponseHandler";

export interface DashboardRangeParams {
  from: string;
  to: string;
}

export interface DashboardQueryParams extends DashboardRangeParams {
  exchanges?: string;
  accounts?: string;
}

const buildDashboardQueryUrl = (party: string, urlPath: string, params?: DashboardQueryParams) =>
  buildQueryUrl(`/${party}/${urlPath}`, params);

export interface GetKPIResponse {
  aum: number;
  balance: {
    free: number;
    locked: number;
  };
  index_impact: number;
  exchanges: string[];
}

export const getKPI = async (
  party: string,
  params: DashboardRangeParams
): Promise<ApiResponse<GetKPIResponse>> => {
  const url = buildDashboardQueryUrl(party, "stats_overview", params);
  return ResponseHandler.handler<GetKPIResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface getKPIAUMResponse {
  aum: string;
}

export const getKPIAUM = async (
  party: string,
  params: DashboardRangeParams
): Promise<ApiResponse<getKPIAUMResponse>> => {
  const url = buildDashboardQueryUrl(party, "stats_aum", params);
  return ResponseHandler.handler<getKPIAUMResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetKPIBalancesResponse {
  free: string;
  locked: string;
}

export const getKPIBalances = async (
  party: string,
  params: DashboardRangeParams
): Promise<ApiResponse<GetKPIBalancesResponse>> => {
  const url = buildDashboardQueryUrl(party, "stats_balance", params);
  return ResponseHandler.handler<GetKPIBalancesResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetKPIIndexResponse {
  depth_impact: string;
  volume_impact: string;
  index_impact: string;
}

export const getKPIIndex = async (
  party: string,
  params: DashboardRangeParams
): Promise<ApiResponse<GetKPIIndexResponse>> => {
  const url = buildDashboardQueryUrl(party, "stats_index", params);
  return ResponseHandler.handler<GetKPIIndexResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface CurrencyData {
  [key: string]: string;
}

export interface FundingsExchange {
  [key: string]: CurrencyData;
}

interface PNLChartsResponse {
  delta_base: ChartPointResponse[] | null;
  delta_quote: ChartPointResponse[] | null;
  price: ChartPointResponse[] | null;
}

export interface GetBalancePNLResponse {
  base_ticker: string;
  current_price_base: string;
  delta_base: string;
  delta_base_usd: string;
  delta_quote_usd: string;
  delta_usd: string;
  fee_usd: string;
  price_growth: string;
  price_net: string;
  fundings: FundingsExchange;
  charts: PNLChartsResponse | null;
}

export interface GetBalancePNLQueryParams extends DashboardQueryParams {
  chart?: boolean;
}

export const getBalancePNL = async (party: string, params: GetBalancePNLQueryParams) => {
  const url = buildDashboardQueryUrl(party, "balance_pnl", params);

  return ResponseHandler.handler<GetBalancePNLResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetPNLResponse {
  all_time: number;
  bars_abs: {
    time: number[];
    value: number[];
  };
  bars_percent: {
    time: number[];
    value: number[];
  };
  deltas: BalancesDeltaPoint[];
}

export const getPNL = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "profit_loss", params);

  return ResponseHandler.handler<GetPNLResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export type BalancesRequestType = "free_locked" | "quote_base";

export type BalancesRequestTypePair<T extends BalancesRequestType> = Split<T, "_">;

type BalancesSeriesResponse = {
  time: number[];
  value: string[];
};

export type BalancesDeltaPoint = {
  time: number;
  delta: FundingsExchange;
  delta_usd: FundingsExchange;
};

export interface GetBalancesResponse<T extends BalancesRequestType> {
  values: {
    a: BalancesSeriesResponse;
    b: BalancesSeriesResponse;
    price_avg: BalancesSeriesResponse;
  };
  legend: {
    a: BalancesRequestTypePair<T>[0];
    b: BalancesRequestTypePair<T>[1];
  };
  deltas: BalancesDeltaPoint[];
}

interface GetBalancesRequestParams<T extends BalancesRequestType> extends DashboardQueryParams {
  type: T;
}

export const getBalances = async <T extends BalancesRequestType>(
  party: string,
  params: GetBalancesRequestParams<T>
) => {
  const url = buildDashboardQueryUrl(party, "balance_overview", params);

  return ResponseHandler.handler<GetBalancesResponse<T>>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

type ExchangesBalances = Record<string, string>;

export interface GetExchangesResponse {
  free_usd: ExchangesBalances;
  locked_usd: ExchangesBalances;
  total_tokens: ExchangesBalances;
  total_usd: ExchangesBalances;
}

export const getExchanges = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "exchange_overview", params);

  return ResponseHandler.handler<GetExchangesResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetBuySellLiquidityResponse {
  buy: {
    all: string[];
    not_our: string[];
    our: string[];
  };
  sell: {
    all: string[];
    not_our: string[];
    our: string[];
  };
  time: number[];
  price_avg: string[];
}

export const getBuySellLiquidity = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "buy_sell_liquidity", params);

  return ResponseHandler.handler<GetBuySellLiquidityResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

type ExchangesVolume = Record<string, string>;
export interface GetVolumeShareResponse {
  another: ExchangesVolume;
  our: ExchangesVolume;
  total: ExchangesVolume;
  stat: {
    all: string;
    another: string;
    our: string;
    percent: string;
  };
}

export const getVolumeShare = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "volume_share", params);

  return ResponseHandler.handler<GetVolumeShareResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetPartiesMetricsResponse {
  created: number;
  exchanges: [
    {
      created: number;
      currencies: string[];
      exchange: string;
    },
  ];
}

export const getPartiesMetrics = async (party: string) =>
  ResponseHandler.handler<GetPartiesMetricsResponse>({
    url: apiUrl(ApiModules.Parties, `${party}/track-metrics`),
    method: "GET",
  });

interface GetPartyConfigResponse {
  slug: string;
  ticker: string;
}

export const getPartyConfig = async (party: string) =>
  ResponseHandler.handler<GetPartyConfigResponse>({
    url: apiUrl(ApiModules.Auth, `/parties/${party}/config`),
    method: "GET",
  });

export interface BalanceItemResponse {
  free: string;
  locked: string;
  total: string;
}

export interface CurrencyBalanceResponse {
  abs: BalanceItemResponse;
  aum: BalanceItemResponse;
  currency: string;
}

export interface BalanceSummaryItemResponse {
  account_name: string;
  account_uuid: string;
  exchange: string;
  base_aum: BalanceItemResponse;
  currencies: CurrencyBalanceResponse[];
  quote_aum: BalanceItemResponse;
  total_aum: BalanceItemResponse;
}

export interface GetAccountsBalancesResponse {
  items: BalanceSummaryItemResponse[];
}

export const getAccountsBalances = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "balance_summary", params);

  return ResponseHandler.handler<GetAccountsBalancesResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetAumSummaryResponse {
  quote: BalanceItemResponse;
  base: BalanceItemResponse;
  total: BalanceItemResponse;
}

export const getAumSummary = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "aum_summary", params);

  return ResponseHandler.handler<GetAumSummaryResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface BalanceDynamicsItemResponse {
  abs: string;
  usd: string;
  currency: string;
  percent: string;
}

export interface GetBalanceDynamicsResponse {
  items: BalanceDynamicsItemResponse[];
}

export const getBalanceDynamics = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "balance_dynamics", params);

  return ResponseHandler.handler<GetBalanceDynamicsResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetTotalUSDChartResponse {
  points: ChartPointResponse[];
}

export const getTotalUSDChart = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "total_usd_chart", params);

  return ResponseHandler.handler<GetTotalUSDChartResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetCurrencyChartResponse {
  points: ChartPointResponse[];
}

interface GetCurrencyChartQueryParams extends DashboardQueryParams {
  currency: string;
}

export const getCurrencyChart = async (party: string, params: GetCurrencyChartQueryParams) => {
  const url = buildDashboardQueryUrl(party, "exact_currency_chart", params);

  return ResponseHandler.handler<GetCurrencyChartResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetCurrenciesResponse {
  currencies: string[];
}

export const getCurrencies = async (party: string) => {
  const url = buildDashboardQueryUrl(party, "currencies");

  return ResponseHandler.handler<GetCurrenciesResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetAveragePriceResponse {
  price: ChartPointResponse[];
  volume: ChartPointResponse[];
}

export const getAveragePrice = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "avg_price_extended", params);

  return ResponseHandler.handler<GetAveragePriceResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetPairsSummaryItemResponse {
  exchange: string;
  index_impact: string | null;
  liquidity_share: string;
  pair: string;
  price: string | null;
  price_change: string | null;
  spread: string | null;
  volume_all: string;
  volume_our: string;
  volume_share: string;
  depth_all_negative: string;
  depth_all_positive: string;
  depth_our_negative: string;
  depth_our_positive: string;
  liquidity_share_negative: string;
  liquidity_share_positive: string;
}

type GetPairsSummaryTotalResponse = Schema<
  Omit<GetPairsSummaryItemResponse, "exchange" | "pair">,
  string
>;
export interface GetPairsSummaryResponse {
  items: GetPairsSummaryItemResponse[];
  total: GetPairsSummaryTotalResponse;
}

export const getPairsSummary = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "exchange_pairs_summary", params);

  return ResponseHandler.handler<GetPairsSummaryResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetPairsSpreadItemResponse {
  base: string;
  exchange: string;
  points: ChartPointResponse[];
  quote: string;
}

export interface GetPairsSpreadResponse {
  items: GetPairsSpreadItemResponse[];
}

export const getPairsSpread = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "exchange_pairs_spread", params);

  return ResponseHandler.handler<GetPairsSpreadResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetArbitrageResponse {
  min: ChartPointResponse[];
  max: ChartPointResponse[];
}

export const getArbitrage = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "arbitrage", params);

  return ResponseHandler.handler<GetArbitrageResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetLiquidityItemResponse {
  step: string;
  value: string;
  points: ChartPointResponse[];
}

export interface GetLiquidityResponse {
  all: GetLiquidityItemResponse[];
  our: GetLiquidityItemResponse[];
}

export const getLiquidity = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "liquidity", params);

  return ResponseHandler.handler<GetLiquidityResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

interface GetFeesByExchangeItemResponse {
  amount_usd: string;
  currency: string;
  percent: string;
}

export interface GetFeesByExchangeResponse {
  items: GetFeesByExchangeItemResponse[];
  total: {
    amount_usd: string;
    percent: string;
  };
}

export const getFeesByExchange = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "fee_by_exchange", params);

  return ResponseHandler.handler<GetFeesByExchangeResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

interface GetFeesByCurrencyItemResponse {
  amount_usd: string;
  currency: string;
  amount: string;
}

export interface GetFeesByCurrencyResponse {
  items: GetFeesByCurrencyItemResponse[];
  total: {
    amount_usd: string;
    amount: string;
  };
}

export const getFeesByCurrency = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "fee_by_currency", params);

  return ResponseHandler.handler<GetFeesByCurrencyResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};
